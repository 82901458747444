<template>
    <section class="cotizacion-ver container-fluid overflow-auto custom-scroll">
        <navbar-admin titulo="Cotizaciones > Cotización 0001"  />
        <!-- info cotizacion -->
        <div class="row my-4">
            <div class="col-12 col-md-auto col-lg-12">
                <div class="bg-f5 border br-8 pr-4 br-50-6">
                    <div class="row mx-0 contenedor-info d-middle">
                        <img :src="detalleInfo.imagen | helper-storage" alt="" class="img-logo-op wh-126 rounded-circle position-relative">
                        <div class="col py-2">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <p class="f-18 f-700">Cotización {{ detalleInfo.referencia }}</p>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div class="d-flex justify-content-end">
                                        <p class="f-18 f-600 my-auto mr-3">
                                            Total {{ formatoMoneda(detalleInfo.total) }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col-12 col-lg-6">
                                    <p class="f-14 f-500">{{ detalleInfo.empresa }}</p>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <p class="f-600 f-14 text-right">
                                        Días de crédito:
                                        <span class="f-300">{{ detalleInfo.dias_credito}} días</span>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="row">
                                        <div class="col-auto my-auto">
                                            <div class="d-middle">
                                                <el-tooltip content="Materiales cotizados" placement="bottom" effect="light" visible-arrow>
                                                    <i class="icon-format-list-numbered" />
                                                </el-tooltip>
                                                <p class="f-500 f-14">{{ detalleInfo.materiales }}</p>
                                            </div>
                                        </div>
                                        <div class="col-auto my-auto">
                                            <p class="f-600 f-14 text-right">
                                                Fecha de solicitud:
                                                <span class="f-300">{{ detalleInfo.fecha_solicitud | helper-fecha('DD MMM. YYYY') }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 ">
                                    <p class="f-600 f-14 text-right">
                                        Vigencia de precio: 
                                        <span class="f-300">{{ detalleInfo.dias_vigencia_precio }} días</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- comentario -->
        <div v-if="detalleInfo && detalleInfo.comentarios" class="bg-f5 border br-8 p-3">
            <div class="d-middle">
                <i class="icon-message-reply-text" />
                <p class="f-14 f-500">Comentario</p>
            </div>
            <p class="f-12 lh-17">{{ detalleInfo.comentarios }}</p>
        </div>
        <!-- titulo + filtro -->
        <titulo-divisor titulo="Materiales cotizados">
            <div class="d-middle gap-1">
                <el-input v-model="filter.nombre" @keydown.native.enter="listMateriales" placeholder="Buscar cotizaciones" size="small" />
                <filter-indicator @openModalFilter="abrirModalFiltros" @clearFilter="limpiarFiltro" :count="count" />
            </div>
        </titulo-divisor>
        <!-- tabla -->
        <div class="row">
            <div class="col-12">
                <el-table ref="multipleTable" :data="materiales" style="width: 100%" class="f-12">
                    <el-table-column label="Nombre" prop="nombre" width="250" show-overflow-tooltip />
                    <el-table-column label="U.M" prop="unidad_medida" align="center" />
                    <el-table-column label="Cantidad" prop="cantidad" align="center" />
                    <el-table-column label="Valor unitario" prop="valor_unidad" align="center">
                        <template slot-scope="scope">
                            {{ formatMoney(scope.row) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor Parcial" align="center">
                        <template slot-scope="scope">
                            <p>{{ formatoMoneyTotal(scope.row) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Garantía" prop="garantia" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.garantia || '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Días de entrega" prop="dias_entrega" align="center" />
                </el-table>
            </div>
        </div>
        <!-- partials  -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 my-4">
                    <p class="input-label-top">Nombre</p>
                    <el-input v-model="filter.nombre" type="text" size="small"/>
                </div>
                <div class="col-11 my-4">
                    <p class="input-label-top">Unidad de medida</p>
                    <el-select v-model="filter.id_unidad_medida" clearable filterable placeholder="Seleccionar unidad de medida" size="small" class="w-100">
                        <el-option v-for="unit in select_measure_units" :key="unit.id" :label="`${unit.unidad_medida} (${unit.sigla})`" :value="unit.id" />
                    </el-select>
                </div>
                <div class="col-11 my-3">
                    <p class="input-label-top">Rango de valor</p>
                    <el-slider v-model="rangueValue" range :min="rangos.cantidad_min" :max="rangos.cantidad_max" :disabled="disabledSlider" :format-tooltip="formatTooltipRange" />
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="limpiarFiltro"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listMateriales"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            idCotizacion: this.$route.params.id_cotizacion,
        }
    },
    computed: {
        ...mapGetters({
            select_measure_units: 'selects/selects/select_measure_units',
            detalleInfo: 'cotizacion/detalleCotizacion/detalleInfo',
            materiales: 'cotizacion/detalleCotizacion/materiales',
            select_monedas: 'selects/selects/select_monedas',
            filtros: 'cotizacion/detalleCotizacion/filtros',
            rangos: 'cotizacion/detalleCotizacion/rangos',
        }),
        filter: {
            get(){ return this.filtros },
            set(val){ this.set_filtros(val) }
        },
        rangueValue:{
            get(){ return [this.filter.cantidad_min, this.filter.cantidad_max] },
            set(val){ this.filter.cantidad_min = val[0], this.filter.cantidad_max = val[1] }
        },
        count(){
            return this.filter.count()
        },
        disabledSlider(){
            return this.rangos.cantidad_min == this.rangos.cantidad_max ? true : false
        }
    },
    async created () {
        await this.Action_get_detalle_info(this.idCotizacion);
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['cotizaciones.main']);
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs', [
            {
                position: 1,
                breadcumb: {
                    name: `Cotización ${this.detalleInfo.referencia}`,
                }
            }
        ]);
        await this.Action_get_rango_materiales(this.idCotizacion);
        await this.getSelects(['Action_get_select_monedas'])
        this.Action_get_select_measure_units();
        this.limpiarFiltro()
    },
    methods:{
        ...mapActions({
            Action_get_select_measure_units: 'selects/selects/Action_get_select_measure_units',
            Action_get_rango_materiales: 'cotizacion/detalleCotizacion/Action_get_rango_materiales',
            Action_get_detalle_info: 'cotizacion/detalleCotizacion/Action_get_detalle_info',
            Action_get_materiales: 'cotizacion/detalleCotizacion/Action_get_materiales',
            Action_clear_filters: 'cotizacion/detalleCotizacion/Action_clear_filters',
            getSelects: 'selects/selects/sync'
        }),
        async listMateriales(){
            await this.Action_get_materiales({idCotizacion: this.idCotizacion});
        },
        formatTooltipRange(val){
            return this.formatoMoneda(val)
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        limpiarFiltro(){
            this.Action_clear_filters()
            this.listMateriales()
        },
        formatMoney(e) {
            return `${this.select_monedas.find(el => el.id === e.id_moneda)?.sigla} $${e.valor_unidad}`
        },
        formatoMoneyTotal(e) {
            return `${this.select_monedas.find(el => el.id === e.id_moneda)?.sigla} $${e.valor_unidad * Number(e.cantidad)}`
        }
    }
}
</script>

<style lang="scss" scoped>
.cotizacion-ver{
    height: calc(100vh - 195px);
    .br-50-6{
        border-radius: 50rem 6rem 6rem 50rem !important;
    }
    .contenedor-info{
        .img-logo-op{
            left: -3px;
            top: -1px;
        }
    }
}
</style>